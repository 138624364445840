import { useQuery } from "@tanstack/react-query";
import { FC } from "react";
import classNames from "classnames";
import query from "../../query";
import Refresh from "../../components/Refresh";
import Ticket from "./Ticket";
import { Scroll, Wrapper } from "./styled";
import Loading from "../../components/Icons/Loading";

const MyBets: FC = () => {
  const getBetTickets = query.getBetTickets({
    page: 1,
    limit: 10,
    type: ["normal", "step", "comboStep"],
    criteriaDate: "ALL",
  });

  const { data, isLoading, refetch } = useQuery(
    getBetTickets.key,
    getBetTickets.queryFn
  );

  const onClickRefresh = () => {
    refetch();
  };

  return (
    <div className="flex-1 h-full flex flex-col">
      <Wrapper>
        <Refresh
          isLoading={isLoading}
          onClick={onClickRefresh}
          initialCounter={90}
        />
      </Wrapper>

      <Scroll
        className={classNames({
          "h-full flex justify-center items-center": isLoading,
        })}
      >
        {isLoading ? (
          <Loading size={25} />
        ) : Boolean(data?.docs?.length) ? (
          data?.docs.map((ticket: any) => (
            <Ticket
              key={ticket.betId}
              betId={ticket.betId}
              betAt={ticket.createDate}
              status={ticket.status}
              matchs={ticket.sport}
              betType={ticket.betType}
              amount={Math.abs(ticket.betAmt.$numberDecimal)}
            />
          ))
        ) : (
          <p className="p-5">You do not place bets, Placed bets will appear here.</p>
        )}
      </Scroll>
    </div>
  );
};

export default MyBets;
