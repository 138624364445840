import {
  FC,
  ChangeEventHandler,
  MutableRefObject,
  memo,
  FormEventHandler,
} from "react";
import { NumericFormat } from "react-number-format";
import { commaNumber } from "../../../utils/commaNumber";
import { Button, ButtonType } from "../../../components/Button";
import { StyledInput, Suffix, Wrapper, ClickAbled } from "./styled";
import { BetType } from "../../../types/common";
import Loading from "../../../components/Icons/Loading";

export type PlaceBetFormProps = {
  isLoading?: boolean;
  amount?: number;
  betType?: BetType;
  pairs?: number;
  min?: number;
  max?: number;
  maxReturn?: number;
  isAcceptAnyOdds?: boolean;
  oddsSummary?: number;
  isLive?: boolean;
  onClickMinMax?: (amount: number) => void;
  onCancel?: () => void;
  onSubmit?: FormEventHandler<HTMLFormElement>;
  onChangeAmount?: ChangeEventHandler<HTMLInputElement>;
  onBlurAmount?: ChangeEventHandler<HTMLInputElement>;
  onChangeAcceptAnyOdds?: ChangeEventHandler<HTMLInputElement>;
  InputRef?: MutableRefObject<HTMLInputElement | undefined>;
};

const PlaceBetForm: FC<PlaceBetFormProps> = ({
  amount = 0,
  min = 0,
  max = 0,
  maxReturn,
  betType = "normal",
  oddsSummary = 1,
  isLive = false,
  isLoading = false,
  isAcceptAnyOdds = false,
  onClickMinMax = () => {},
  onCancel,
  pairs = 0,
  onSubmit,
  onChangeAmount,
  onBlurAmount,
  onChangeAcceptAnyOdds,
  InputRef,
}) => {
  const handleClickMin = () => {
    onClickMinMax(min);
  };

  const handleClickMax = () => {
    onClickMinMax(max);
  };

  const isMultipleMatch = betType === "step" || betType === "comboStep";

  return (
    <Wrapper
      isLive={isLive}
      noValidate
      onSubmit={onSubmit}
      className="p-4 flex items-start flex-col"
    >
      <label className="cursor-pointer mb-1">
        <input
          type="checkbox"
          className="mr-1"
          checked={isAcceptAnyOdds}
          onChange={onChangeAcceptAnyOdds}
          disabled={isLoading}
        />
        Accept any odds
      </label>
      <div className="relative">
        <NumericFormat
          getInputRef={InputRef}
          className="text-right py-2 pl-2 pr-12 box-border rounded-lg w-full"
          value={amount}
          thousandSeparator=","
          onChange={onChangeAmount}
          onBlur={onBlurAmount}
          customInput={StyledInput}
          disabled={isLoading}
        />
        <Suffix>บาท</Suffix>
      </div>

      {isMultipleMatch && (
        <div className="flex justify-between w-full">
          <div className="text-sm">คู่</div>
          <div className="text-sm">{pairs}/12</div>
        </div>
      )}

      {isMultipleMatch && (
        <div className="flex justify-between w-full">
          <div className="text-sm">
            {betType === "step" ? "Palay" : "Combo"}
          </div>
          <div className="text-sm">@{oddsSummary}</div>
        </div>
      )}

      <div className="flex justify-between w-full">
        <div>
          <ClickAbled disabled={isLoading} onClick={handleClickMin}>
            ขั้นต่ำ
          </ClickAbled>{" "}
          -{" "}
          <ClickAbled disabled={isLoading} onClick={handleClickMax}>
            สูงสุด
          </ClickAbled>
        </div>
        <div>
          <ClickAbled disabled={isLoading} onClick={handleClickMin}>
            {commaNumber(min)}
          </ClickAbled>{" "}
          -{" "}
          <ClickAbled disabled={isLoading} onClick={handleClickMax}>
            {commaNumber(max)}
          </ClickAbled>
        </div>
      </div>

      <div className="flex justify-between w-full mb-2">
        <div className="text-sm">จ่ายสูงสุด</div>
        <div className="text-sm">{commaNumber(maxReturn || amount)}</div>
      </div>

      <div className="flex justify-between w-full gap-2">
        <Button
          disabled={isLoading}
          onClick={onCancel}
          className="flex-1 p-2 text-sm flex justify-center items-center"
          buttonType={ButtonType.PRIMARY}
          type="button"
        >
          {isLoading && <Loading />}ยกเลิก
        </Button>
        <Button
          disabled={isLoading}
          className="flex-1 p-2 text-sm flex justify-center items-center"
          buttonType={ButtonType.SECONDARY}
          type="submit"
        >
          {isLoading && <Loading />}
          วางเดิมพัน
        </Button>
      </div>
    </Wrapper>
  );
};

export default memo(PlaceBetForm);
