import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useMemo, useRef } from "react";
import produce from "immer";
import BetSlip from "../BetSlip";
import MyBets from "../MyBets";
import query from "../../query";
import mutation from "../../mutation";
import Tabs from "../../components/Tabs";
import SportBarService from "../../services/sportBar";

const sportBarService = new SportBarService();

const BetsTabs = () => {
  const betTicketInfoPayloadQuery = query.betTicketInfoPayload();
  const betTicketInfoPayload = useQuery(
    betTicketInfoPayloadQuery.key,
    betTicketInfoPayloadQuery.queryFn,
    { refetchOnMount: false, keepPreviousData: true }
  );

  const getBetTicketsQuery = query.getBetTickets({
    page: 1,
    limit: 10,
    type: ["normal", "step", "comboStep"],
    criteriaDate: "ALL",
  });

  const getBetTickets = useQuery(
    getBetTicketsQuery.key,
    getBetTicketsQuery.queryFn
  );

  const queryClient = useQueryClient();
  const currentTabIndexQuery = query.currentTabIndex();
  const currentTabIndex = useQuery(
    currentTabIndexQuery.key,
    currentTabIndexQuery.queryFn,
    { refetchOnMount: false, keepPreviousData: true, initialData: 0 }
  );

  const updateCurrentTabIndex = useMutation(mutation.updateCurrentTabIndex, {
    onSuccess: (index) => {
      queryClient.setQueryData(["currentTabIndex"], index);
    },
  });

  const tabs = useRef([
    {
      name: "Bet Slip",
      component: <BetSlip />,
      onClick: (index: number) => {
        updateCurrentTabIndex.mutate(index);
        sportBarService.collapse();
      },
    },
    {
      name: "My Bets",
      component: <MyBets />,
      badgeNo: 0,
      onClick: (index: number) => {
        updateCurrentTabIndex.mutate(index);
        sportBarService.collapse();
      },
    },
  ]);

  const updatedTabs = useMemo(
    () =>
      produce(tabs.current, (draft) => {
        draft[0].badgeNo = betTicketInfoPayload.data?.length || 0;
        draft[1].badgeNo = getBetTickets.data?.totalDocs || 0;
      }),

    [getBetTickets.data, betTicketInfoPayload.data]
  );

  return (
    <Tabs
      className="mt-4 overflow-y-auto flex-1 flex flex-col"
      data={updatedTabs}
      currentTabIndex={currentTabIndex.data}
    />
  );
};

export default BetsTabs;
