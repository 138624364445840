import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { FC, useCallback, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Datepicker from "react-tailwindcss-datepicker";

import { COLUMNS } from "./config";
import { StyledTable } from "./styled";
import Table from "../../components/Table";
import { useDateRangeFilter } from "../../contrainers/DateRangeFilter/useDateRangeFilter";
import query from "../../query";
import { commaNumber } from "../../utils/commaNumber";
// import StatementDetailTable from "./StatementDetailTable";
import TicketTable from "../../contrainers/TicketTable";
import moment from "moment";

const Statement: FC = () => {
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const dateRange = useDateRangeFilter();

  const [searchParams, setSearchParams] = useSearchParams();
  const date = searchParams.get("date");
  const filter = useMemo(
    () => ({
      startDate: dayjs(dateRange.value?.startDate).startOf("day").toISOString(),
      endDate: dayjs(dateRange.value?.endDate).endOf("day").toISOString(),
      offsetTimezone: dayjs().format("Z"),
    }),
    [dateRange.value]
  );
  const statementQuery = query.statement(filter);
  const statement = useQuery(statementQuery.key, statementQuery.queryFn);

  const formatData = (data?: any[]) => {
    if (!data) return [];

    return data.map((item, index) => {
      const { winLose } = item;
      return {
        no: index + 1,
        date: item._id,
        winLose: commaNumber(
          (Number(winLose?.$numberDecimal) - item.comm).toFixed(2)
        ),
        betAmount: commaNumber(Math.abs(item.stake.$numberDecimal).toFixed(2)),
        commission: commaNumber((item.comm ?? 0).toFixed(2)),
        total: commaNumber(Number(winLose?.$numberDecimal).toFixed(2)),
      };
    });
  };

  const formattedData = useMemo(
    () => formatData(statement.data),
    [statement.data]
  );

  const onClickDate = useCallback(
    (newDate: string /* DD-MM-YYYY */) => {
      const isValid = dayjs(newDate, "DD-MM-YYYY", true).isValid();
      if (!isValid) {
        return;
      }

      setSearchParams({ date: newDate });
    },
    [setSearchParams]
  );

  const onChangePage = useCallback((page: number) => {
    setPage(page);
  }, []);

  return (
    <div className="desktop:container desktop:mx-auto desktop:px-3 mobile:pt-3">
      <h3 className="text-2xl font-bold mb-5">{t("Statement")}</h3>
      {!date && (
        <div className="bg-white p-4">
          <div className="flex desktop:items-center mobile:flex-col desktop:flex-row desktop:space-x-4 mb-4">
            <div>{t("Date time")}:</div>
            <div>
              <Datepicker
                primaryColor={"emerald"}
                value={dateRange.value}
                onChange={dateRange.onChange}
                showShortcuts={true}
                displayFormat="DD-MM-YYYY"
                containerClassName="desktop:w-80 z-[999]"
                maxDate={new Date()}
                minDate={dayjs().subtract(1, "year").toDate()}
                separator={"to"}
              />
            </div>
          </div>
        </div>
      )}

      <div className="bg-white">
        {date ? (
          <>
            <h3 className="px-4 py-4">
              <Link className="text-blue-600 hover:underline" to="/statement">
                Statement
              </Link>{" "}
              / {date}
            </h3>
            <div className="desktop:mx-3">
              <div className="w-full overflow-x-scroll bg-white ">
                <TicketTable
                  startDate={moment(date, "DD-MM-YYYY")
                    .startOf("day")
                    .toISOString()}
                  endDate={moment(date, "DD-MM-YYYY")
                    .endOf("day")
                    .toISOString()}
                  page={page}
                  limit={50}
                  onClickPagination={onChangePage}
                  status={["done", "cancelled", "rejected"]}
                  dateField="settleDate"
                />
              </div>
            </div>
          </>
        ) : (
          <StyledTable className="w-full overflow-x-scroll">
            <Table
              placeholder={t("No statement on this date range") as string}
              isLoading={statement.isLoading}
              data={formattedData}
              columns={COLUMNS}
              onClickDate={onClickDate}
            />
          </StyledTable>
        )}
      </div>
    </div>
  );
};

export default Statement;
