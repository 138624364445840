import axios from "axios";
import { CONFIG } from "../../config";

export type StatementFilter = {
  // type: string[];
  startDate: string; // "DD-MM-YYYY",
  endDate: string; // "DD-MM-YYYY",
  page?: number;
  limit?: number;
};

class StatementService {
  public get = async (filter: StatementFilter) => {
    const result = await axios.post(
      `${CONFIG.API_URL}/c/m/winLoseGroupByDate`,
      filter
    );

    return result?.data?.data;
  };

  public getDetail = async (filter: StatementFilter) => {
    const result = await axios.post(
      `${CONFIG.API_URL}/c/m/winLoseListByDate`,
      filter
    );
      console.log("s",result?.data?.data)
    return result?.data?.data;
  };
}

export default StatementService;
