import { CellContext } from "@tanstack/react-table";
import moment from "moment";
import { cleanCornerId } from "../../../utils/cleanConnerId";
import { FormattedFeed } from "../../../types/feed";
import { CustomCellContext } from "../../../types/table";

const Date = (row: CellContext<FormattedFeed, unknown> & CustomCellContext) => {

  const { original } = row.row;
  if (original.betPrice_order !== 0) {
    return "";
  }

  let timeStr;

  if (original.match_i.lt !== "Live") {
    // live match
    timeStr = original.match_i.lt;
  } else {
    const date = row.getValue<string>();
    if (!date) {
      return "";
    } else {
      const time = moment.utc(date);
      timeStr = moment().isSame(time, "day")
        ? time.format("HH:mm")
        : time.format("DD/MM HH:mm");
    }
  }

  return (
    <>
      <div>{timeStr}</div>
      <div className="flex justify-center space-x-2 items-center">
        {!!original.match_st && (
          <a href={original.match_st} target="_blank" rel="noreferrer">
            <img src="/svg/static.svg" width={16} alt="" />
          </a>
        )}
        {original.match_isChannel && (
          <a
            href={`https://askmebet.live/${row?.language}/match/${cleanCornerId(
              original.match_k
            )}`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              width="23px"
              height="14px"
              src={`${process.env.PUBLIC_URL}/live-streaming.webp`}
              alt=""
            />
          </a>
        )}
      </div>
      {/* <div>
        {!!original.betPrice_ah?.sbo && (
          <p className="text-pink-600 font-bold">SBO</p>
        )}
        {!!original.betPrice_ah?.ibc && (
          <p className="text-sky-600 font-bold">IBC</p>
        )}
      </div> */}
    </>
  );
};

export default Date;
